.main_container {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: space-between;
  z-index: 1;

  margin-left: 340px;

  @media (max-width: 1700px) {
    margin-left: 0;
  }

}

.App-link {
  color: #000000;
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 1000;
  line-height: 30.12px;
  padding: 20px 0;
  margin-top: 22px;
  border: 1px solid;
  border-image-source: linear-gradient(116.97deg, rgba(255, 255, 255, 0) 16.71%, rgba(255, 255, 255, 0.4) 98.19%);
  background: #FFB804;
  border-radius: 24px;
  animation: pulse 2s infinite ease-in-out; 
}

.App-link:hover {
  background: #FFB804;
  box-shadow: 0px 0px 60px 0px #FFB804;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0px 0px 10px 0px #FFB804;
  }
  50% {
    box-shadow: 0px 0px 30px 10px #FFB804;
  }
}

.content_block {
  display: flex;
  flex-direction: column;
  width: 465px;
  gap: 10px;
}

.image_block {
  position: relative;
  width: 535px;
  height: 900px;
  top: -240px;
}

.zeus {
  position: absolute;
  width: 495px;
  height: 900px;
  z-index: 2;
}

.coins {
  position: absolute;
  width: 550px;
  height: 690px;
  top: 260px;
  left: 0px;
}

.diamonds {
  position: absolute;
  width: 550px;
  height: 690px;
  top: 190px;
  left: 0px;
}

.percent {
  font-size: 64px;
  font-weight: 900;
  line-height: 80px;  
  margin: 0;
}

.text {
  font-size: 64px;
  font-weight: 900;
  line-height: 80px;
  white-space: nowrap;
  background: linear-gradient(90deg, #E0AA3E 0%, #F9F295 33.4%, #E0AA3E 67%, #F7EF8A 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text_desc {
  font-size: 36px;
  font-weight: 900;
  line-height: 45px;
  background: #0075FF;
  padding: 8px 16px;
  border-radius: 16px;
  width: max-content;
}

.footer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  align-items: start;
  max-width: 1202px;
  width: 100%;
  justify-content: space-between;

  span {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }


}

.path {
  margin-top: 80px;
}