.App {
  background: 
  url('/public/img/shadow.png') no-repeat center center, 
  url('/public/img/mainBg.png') no-repeat center center; 
background-size: cover, cover; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px 100px 0 100px; 
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1300px) {
    padding: 20px 24px 0 24px;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }
}

.olympus {
  width: 182px;
  height: 140px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  
  @media (max-width: 1300px) {
    left: 92%;
    width: 130px !important;
    height: 100px !important;
  }

  @media (max-width: 700px) {
    left: 90%;
    width: 78px !important;
    height: 60px !important;
  }
}

.App-logo {
  height: 69px;
  width: 177px;
  pointer-events: none;
  z-index: 2;
  margin-left: 340px;


  @media(max-width: 1700px) {
    margin-left: 0;
  }

  @media (max-width: 600px) {
    height: 30px !important;
    width: 100px !important;
  }
}

.limitation {
  width: 18px;
  height: 18px;
}








